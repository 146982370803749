@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;600;800&display=swap");

$color-light: rgba(255, 255, 255, 1);
$color-mid: rgba(197, 195, 198, 1);
$color-dark: rgb(34, 34, 34);
$color-primary: rgba(249, 214, 74, 1);
$color-accent: rgba(58, 155, 234, 1);

* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: $color-primary;
}

html {
  scroll-behavior: smooth;
}

h2 {
  color: $color-dark;
  text-align: center;
}

body {
  margin: 0;
  font-family: "Overpass", sans-serif;
  font-size: 1em;
}

button {
  font-size: inherit;
}

.full-width {
  display: block;
  width: 100%;
}

.btn {
  border: 1px solid $color-primary;
  color: $color-primary;
  text-transform: uppercase;
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: 15px;
  margin-top: 1em;
}

.btn-inverted {
  border: 1px solid $color-dark;
  color: $color-dark;
  background-color: $color-primary;
}

.btn-inverted:active {
  background-color: lighten($color-primary, 10);
}

a.btn:hover {
  background-color: $color-primary;
  color: $color-dark;
  transition: all 0.2s;
}

section h2 {
  text-transform: uppercase;
}

.card {
  text-align: center;
  border: 1px solid $color-primary;
  border-radius: 15px;
  padding: 1.5em 0.6em;
  .card-icon {
    font-size: 3em;
  }
}

//Navigation
.main-nav {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $color-primary;
  padding: 0 1em;
  margin: 0;
  font-size: 1.5em;
  .nav-wrapper {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 800px) {
    flex-direction: row;
  }
  .brand {
    color: $color-dark;
    font-weight: 800;
    font-size: 1.2em;
    text-transform: uppercase;
    margin-right: auto;
  }
  .hamburger {
    color: $color-dark;
    position: absolute;
    top: 0em;
    right: 1em;
    font-size: 1.2em;
  }
  ul.main-nav-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    text-transform: uppercase;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    perspective: 100px;
    z-index: 999;
  }
  .main-nav-item a {
    color: $color-dark;
    display: inline-block;
    padding: 0em;
    margin: 0.1em 1em;
    border-bottom: 3px solid rgba(34, 34, 34, 0);
    transition: all 0.3s;
  }
  .main-nav-item a:hover,
  .main-nav-item a:active {
    border-bottom: 3px solid rgba(34, 34, 34, 1);
  }
  [aria-expanded="false"] ~ .main-nav-list {
    max-height: 0px;
    overflow: hidden;
    transform: rotateX(90deg);
    transition: all 0.5s;
  }
  [aria-expanded="true"] ~ .main-nav-list {
    max-height: 300px;
    transform: rotateX(0deg);
    transition: all 0.7s;
    // margin-bottom: -50px;
  }
  @media (min-width: 800px) {
    .nav-wrapper {
      flex-direction: row;
      // padding: 0.2em;
      .hamburger ~ ul.main-nav-list {
        max-height: 300px;
        width: 100%;
        transform: rotateX(0deg);
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
      }
      .hamburger {
        display: none;
      }
    }
  }
}

// Hero Section Styles
section.intro {
  background-color: $color-dark;
  color: $color-light;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  .intro-content {
    max-width: 750px;
    h1 {
      font-size: 2rem;
      margin-bottom: 0.2em;
    }
    p {
      line-height: 1.7;
      font-size: 1.2rem;
      font-weight: 200;
      padding-top: 0;
      margin: 0;
      span {
        color: $color-primary;
      }
    }
    .social ul {
      list-style-type: none;
      display: flex;
      padding: 0;
      li {
        padding: 0em 0.3em;
        font-size: 1.5rem;
      }
      & a {
        color: $color-light;
      }
      & a:hover {
        color: $color-primary;
        transition: all 0.2s;
      }
    }
  }
}

.about,
.contact-me {
  padding: 7.5em 2em;
  display: flex;
  max-width: 800px;
  margin: 0 auto 0.5em;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
}

// About Section

.about {
  @media (max-width: 800px) {
    padding-top: 190px;
    margin-top: -50px;
    margin-bottom: 0;
  }
  .profile-image {
    border-radius: 50%;
  }
  .about-content {
    h2 {
      text-align: center;
    }
    p {
      color: $color-mid;
      text-align: justify;
      line-height: 1.5;
    }
  }
  .interests {
    display: grid;
    margin: 0.5em 0;
    gap: 2em;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    .section-heading {
      grid-column: 1/-1;
    }
  }
}

.contact-me {
  @media (max-width: 800px) {
    padding-top: 170px;
    margin-top: -50px;
  }
  font-size: 1em;
  padding-top: 3em;
  & > * {
    display: block;
    width: 100%;
  }

  label {
    display: none;
  }

  .form-group {
    position: relative;
  }

  input,
  textarea {
    width: 100%;
    padding: 0.8rem 1rem 0.8rem 2.7rem;
    font-size: 1rem;
    margin: 0.4em 0 1.2em;
    border: 3px solid lightgrey;
  }

  input:focus,
  textarea:focus {
    border: 3px $color-primary solid;
    outline: none;
  }

  .form-icon {
    position: absolute;
    font-size: 1.3em;
    top: 14px;
    left: 12px;
    color: rgba(0, 0, 0, 0.3);
  }

  textarea {
    height: 300px;
  }
  .submit {
    font-weight: bold;
    font-size: 1.2em;
    letter-spacing: 1px;
    border-width: 2px;
  }
  .submit:active {
    outline: none;
  }
}
